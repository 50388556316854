/* eslint-disable react/prop-types */
import { resetAccessToken } from 'config/axiosDefault';
import { createContext, useState, useContext, useEffect } from 'react';
import { ACCESS_TOKEN_KEY, USER_KEY } from 'utils/constant';

const getLoggedInUserFromLocalStorage = () => {
  const userStr = localStorage.getItem(USER_KEY);
  const authToken = localStorage.getItem(ACCESS_TOKEN_KEY);
  return userStr ? { ...JSON.parse(userStr), authToken } : null;
};

const setLoggedInUserToLocalStorage = (user) => {
  if (user) {
    localStorage.setItem(USER_KEY, JSON.stringify({ ...user, authToken: undefined }));
  } else {
    localStorage.removeItem(USER_KEY);
  }
};

const getTokenFromLocalStorage = () => localStorage.getItem(ACCESS_TOKEN_KEY);

const setTokenToLocalStorage = (token) => {
  if (token) {
    localStorage.setItem(ACCESS_TOKEN_KEY, token);
  } else {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
  }
};

const LoggedInUserContext = createContext(getLoggedInUserFromLocalStorage());

const LoggedInUserProvider = ({ children }) => {
  const [user, setUser] = useState(getLoggedInUserFromLocalStorage());

  useEffect(() => {
    setLoggedInUserToLocalStorage(user);
    setTokenToLocalStorage(user?.authToken);
    resetAccessToken();
  }, [user]);

  return <LoggedInUserContext.Provider value={{ user, setUser }}>{children}</LoggedInUserContext.Provider>;
};

const useLoggedInUser = () => useContext(LoggedInUserContext);

export {
  LoggedInUserContext,
  LoggedInUserProvider,
  useLoggedInUser,
  getLoggedInUserFromLocalStorage,
  setLoggedInUserToLocalStorage,
  setTokenToLocalStorage,
  getTokenFromLocalStorage,
};
