import React from 'react';
import PropTypes from 'prop-types';
import { QueryClient, QueryClientProvider as ReactQueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

export const queryClient = new QueryClient();

const QueryClientProvider = ({ children }) => {
  return (
    <ReactQueryClientProvider client={queryClient}>
      {children}
      <ReactQueryDevtools />
    </ReactQueryClientProvider>
  );
};

QueryClientProvider.propTypes = {
  children: PropTypes.node,
};

export default QueryClientProvider;
