import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import RouteGuard from './RouteGuard';

// main routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const ExpListPage = Loadable(lazy(() => import('views/experiments')));
const ExpDetailPage = Loadable(lazy(() => import('views/exp-detail')));
const IdeaSubmissionsPage = Loadable(lazy(() => import('views/idea-submissions')));
const IdeaSubmissionDetailPage = Loadable(lazy(() => import('views/idea-submission-detail')));
const IdeaSubmissionsDummyPage = Loadable(lazy(() => import('views/idea-submissions/DummyPage')));

// libraries routing
const LibsComponents = Loadable(lazy(() => import('views/libraries/components')));
const UtilsColor = Loadable(lazy(() => import('views/libraries/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/libraries/Shadow')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/libraries/TablerIcons')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: (
    <RouteGuard>
      <MainLayout />
    </RouteGuard>
  ),
  children: [
    {
      path: '/',
      element: <DashboardDefault />,
    },
    {
      path: 'dashboard',
      children: [
        {
          path: 'default',
          element: <DashboardDefault />,
        },
      ],
    },
    {
      path: 'experiments',
      element: <ExpListPage />,
    },
    {
      path: 'experiment/detail/:id',
      element: <ExpDetailPage />,
    },
    {
      path: 'idea-submissions',
      element: <IdeaSubmissionsPage />,
    },
    {
      path: 'idea-submission/detail/:id',
      element: <IdeaSubmissionDetailPage />,
    },
    {
      path: 'idea-submissions-dummy',
      element: <IdeaSubmissionsDummyPage />,
    },
    {
      path: 'lib',
      children: [
        {
          path: 'components',
          element: <LibsComponents />,
        },
      ],
    },
    {
      path: 'lib',
      children: [
        {
          path: 'color',
          element: <UtilsColor />,
        },
      ],
    },
    {
      path: 'lib',
      children: [
        {
          path: 'shadow',
          element: <UtilsShadow />,
        },
      ],
    },
    {
      path: 'icons',
      children: [
        {
          path: 'tabler-icons',
          element: <UtilsTablerIcons />,
        },
      ],
    },
  ],
};

export default MainRoutes;
