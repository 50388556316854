export const BASE_URL = process.env.REACT_APP_BACKEND_URL || '';
export const USER_KEY = 'USER_KEY';
export const ACCESS_TOKEN_KEY = 'ACCESS_TOKEN_KEY';
export const REDIRECT_URL = 'REDIRECT_URL';

export const QUERY_KEYS = {
  IDEAS: 'ideas',
  IDEA: 'idea',
  EXPS: 'experiments',
  EXP: 'experiment',
  JIRA_TOKEN_INFO: 'jira-token-info',
};

export const DATE_FORMATS = {
  MEDIUM: 'MMM D, YYYY',
};

export const DATE_TIME_FORMATS = {
  MEDIUM: 'MMM D, YYYY h:mm A',
};
