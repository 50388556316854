import * as actionTypes from './actions';

export const initialState = {
  open: false,
  message: '',
  variant: 'info',
  vertical: 'top',
  horizontal: 'center',
};

const snackReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SHOW_NOTI:
      const { message, variant, vertical, horizontal } = action.payload || {};
      return {
        ...state,
        open: true,
        message: message || '',
        variant: variant || 'info',
        vertical: vertical || 'top',
        horizontal: horizontal || 'center',
      };
    case actionTypes.CLOSE_NOTI:
      return {
        ...state,
        open: false,
      };
    default:
      return state;
  }
};

export default snackReducer;
