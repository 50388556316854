import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import AuthGuard from './AuthGuard';

// login option 3 routing
const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
const AuthRegister3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));
const Logout = Loadable(lazy(() => import('views/pages/authentication/Logout')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
  path: '/',
  children: [
    {
      path: '/auth/login',
      element: (
        <AuthGuard>
          <AuthLogin3 />
        </AuthGuard>
      ),
    },
    {
      path: '/auth/register',
      element: (
        <AuthGuard>
          <AuthRegister3 />
        </AuthGuard>
      ),
    },
    {
      path: '/auth/logout',
      element: <Logout />,
    },
  ],
};

export default AuthenticationRoutes;
