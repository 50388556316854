// project imports
import SettingsLayout from 'layout/SettingsLayout';
import SettingsMainArea from 'layout/SettingsLayout/SettingsMainArea';
import RouteGuard from './RouteGuard';

// ==============================|| SETTINGS ROUTING ||============================== //

const SettingsRoutes = {
  path: '/',
  element: (
    <RouteGuard>
      <SettingsLayout />
    </RouteGuard>
  ),
  children: [
    {
      path: '/settings/:segment',
      element: <SettingsMainArea />,
    },
  ],
};

export default SettingsRoutes;
