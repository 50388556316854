import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';

// external routings
const IdeaFormPage = Loadable(lazy(() => import('views/idea-form')));
// const SubmissionSuccessPage = Loadable(lazy(() => import('views/idea-form/SubmissionSuccess')));

// ==============================|| OUTSIDE ROUTING ||============================== //

const OutsideRoutes = {
  path: '/',
  children: [
    {
      path: 'submit-your-idea',
      element: <IdeaFormPage />,
    },
  ],
};

export default OutsideRoutes;
