const settingsMenuItems = [
  {
    key: 'profile',
    value: 'Profile',
  },
  {
    key: 'preferences',
    value: 'Settings',
  },
  {
    key: 'jira',
    value: 'Jira Integration',
  },
];

export default settingsMenuItems;
