import React from 'react';
import PropTypes from 'prop-types';
import { useLoggedInUser } from 'context/LoggedInUserContext';
import { Navigate } from 'react-router';

// This wrapper auto redirect loggedin user to home page
const AuthGuard = ({ redirectTo = '/', children }) => {
  const { user } = useLoggedInUser();
  if (user) {
    return <Navigate to={redirectTo} replace />;
  }
  return children;
};

AuthGuard.propTypes = {
  redirectTo: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default AuthGuard;
