/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { CLOSE_DIALOG, SHOW_DIALOG } from 'store/actions';

const useConfirmDialog = () => {
  // const { open } = useSelector((state) => state.dialogConfig);
  const dispatch = useDispatch();

  const showDialog = useCallback(
    (title, desc, onOk, { okBtnText, cancelBtnText, okBtnVariant, cancelBtnVariant, okBtnColor, cancelBtnColor, onCancel } = {}) =>
      dispatch({
        type: SHOW_DIALOG,
        payload: {
          title,
          description: desc,
          onOk,
          onCancel,
          okBtnText,
          cancelBtnText,
          okBtnVariant,
          cancelBtnVariant,
          okBtnColor,
          cancelBtnColor,
        },
      }),
    [],
  );

  const showDeleteDialog = useCallback((desc, deleteHandler) => showDialog('Delete', desc, deleteHandler, { okBtnText: 'Delete' }), []);

  const showRejectDialog = useCallback((desc, rejectHandler) => showDialog('Reject', desc, rejectHandler, { okBtnText: 'Reject' }), []);

  const showLogoutDialog = useCallback(
    (logoutHandler) => showDialog('Logout', 'Are you sure you want to logout?', logoutHandler, { okBtnText: 'Logout' }),
    [],
  );

  const closeDialog = useCallback(
    () =>
      dispatch({
        type: CLOSE_DIALOG,
      }),
    [],
  );

  return { showDialog, showDeleteDialog, showRejectDialog, showLogoutDialog, closeDialog };
};

export default useConfirmDialog;
