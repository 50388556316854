import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Box, Stack, styled, Typography } from '@mui/material';
import classNames from 'classnames';
import { useParams } from 'react-router';
import JiraSegment from 'views/settings/JiraSegment';

const SettingsMainArea = ({ className, ...others }) => {
  const { segment } = useParams();

  const renderSegment = useCallback((seg) => {
    if (seg === 'jira') {
      return <JiraSegment />;
    }
    return (
      <Stack direction="row" alignItems="center" justifyContent="center" p={5} bgcolor={(theme) => theme.palette.grey[50]}>
        <Typography variant="h5" color="secondary" textAlign="center">
          No Data
        </Typography>
      </Stack>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledSettingsMainArea className={classNames('SettingsMainArea', className)} p={1} {...others}>
      {renderSegment(segment)}
    </StyledSettingsMainArea>
  );
};

const StyledSettingsMainArea = styled(Box)({
  maxHeight: 'calc(100vh - 88px)',
  overflowY: 'auto',
});

SettingsMainArea.propTypes = {
  className: PropTypes.string,
};

export default SettingsMainArea;
