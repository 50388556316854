import axios from 'axios';
import { ACCESS_TOKEN_KEY, BASE_URL, REDIRECT_URL } from 'utils/constant';

axios.defaults.baseURL = BASE_URL;
axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`;
axios.interceptors.response.use(
  (r) => r,
  (e) => {
    if (e.response?.status === 401 && !['/auth/logout', '/auth/login', '/auth/register'].includes(location.pathname)) {
      if (location.pathname !== '/') {
        localStorage.setItem(REDIRECT_URL, location.pathname);
      }
      window.location.href = '/auth/logout';
    }
    return Promise.reject(e);
  },
);

export const resetAccessToken = () => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`;
};
