import { Button, Stack, styled, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import notFoundIcon from '../assets/images/icons/404.svg';

const NotFoundPage = () => {
  return (
    <StyledNotFoundPage className="NotFoundPage" justifyContent="center" alignItems="center">
      <StyledIcon src={notFoundIcon} alt="404" />
      <Typography variant="h2" color="secondary" mt={5}>
        NOT FOUND
      </Typography>
      <Typography variant="body2" color="secondary" mt={1}>
        Oops! The page you are looking for is not found.
      </Typography>
      <Link to="/" style={{ margin: '24px 0' }}>
        <Button size="small" variant="contained" color="primary" disableElevation disableRipple disableTouchRipple>
          Go Home
        </Button>
      </Link>
    </StyledNotFoundPage>
  );
};

const StyledNotFoundPage = styled(Stack)({
  height: '100vh',
  maxHeight: '100vh',
  textAlign: 'center',
});

const StyledIcon = styled('img')(({ theme }) => ({
  width: '200px',
  height: 'auto',
  fontSize: theme.spacing(6),
}));

export default NotFoundPage;
