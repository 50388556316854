// assets
import { IconDashboard, IconInfoSquare, IconListDetails, IconTestPipe } from '@tabler/icons';

// constant
const icons = { IconDashboard, IconInfoSquare, IconListDetails, IconTestPipe };

// ==============================|| MAIN MENU ITEMS ||============================== //

const main = {
  id: 'main',
  type: 'group',
  children: [
    {
      id: 'default',
      title: 'Dashboard',
      type: 'item',
      url: '/dashboard/default',
      icon: icons.IconDashboard,
      breadcrumbs: false,
    },
    {
      id: 'experiments',
      title: 'Experiments',
      type: 'item',
      url: '/experiments',
      icon: icons.IconTestPipe,
      breadcrumbs: false,
    },
    {
      id: 'idea-form',
      title: 'Submit Your Idea',
      type: 'item',
      url: '/submit-your-idea',
      icon: icons.IconInfoSquare,
      breadcrumbs: false,
    },
    {
      id: 'idea-submissions',
      title: 'Backlog of Ideas',
      type: 'item',
      url: '/idea-submissions',
      icon: icons.IconListDetails,
      breadcrumbs: false,
    },
  ],
};

export default main;
