import axios from 'axios';

export const getJiraApiToken = async () => {
  const { data } = await axios.get(`/settings/jira/api-token`);
  return data;
};

export const deleteJiraApiToken = async () => {
  const { data } = await axios.delete(`/settings/jira/api-token`);
  return data;
};

export const saveJiraApiToken = async ({ jiraEmail, jiraApiToken, basicToken }) => {
  const { data } = await axios.post(`/settings/jira/api-token`, { jiraEmail, jiraApiToken, basicToken });
  return data;
};
