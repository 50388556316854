import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase } from '@mui/material';

// project imports
import config from 'config/general';
// import Logo from 'ui-component/Logo';
import CroLogo from 'ui-component/CroLogo';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
  <ButtonBase disableRipple component={Link} to={config.defaultPath}>
    {/* <Logo /> */}
    <CroLogo />
  </ButtonBase>
);

export default LogoSection;
