import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import ideaListReducer from './ideaListReducer';
import snackReducer from './snackReducer';
import convertIdeaToExpModalReducer from './convertIdeaToExpModalReducer';
import dialogReducer from './dialogReducer';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  customization: customizationReducer,
  ideaList: ideaListReducer,
  snackConfig: snackReducer,
  ideaToExpModal: convertIdeaToExpModalReducer,
  dialogConfig: dialogReducer,
});

export default reducer;
