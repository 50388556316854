import { lazy } from 'react';
import { useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import { LoggedInUserProvider } from 'context/LoggedInUserContext';
import QueryClientProvider from 'config/reactQuery';
import './config/axiosDefault';

// global components imports
import NavigationScroll from 'layout/NavigationScroll';
const SnackNotification = lazy(() => import('ui-component/SnackNotification'));
const ConvertIdeaToExpModal = lazy(() => import('ui-component/ConvertIdeaToExpModal'));
const AlertDialog = lazy(() => import('ui-component/AlertDialog'));

// ==============================|| APP ||============================== //

const App = () => {
  const customization = useSelector((state) => state.customization);

  return (
    <QueryClientProvider>
      <LoggedInUserProvider>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={themes(customization)}>
            <CssBaseline />
            <NavigationScroll>
              <Routes />
            </NavigationScroll>
            <SnackNotification />
            <ConvertIdeaToExpModal />
            <AlertDialog />
          </ThemeProvider>
        </StyledEngineProvider>
      </LoggedInUserProvider>
    </QueryClientProvider>
  );
};

export default App;
