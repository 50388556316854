import { Outlet } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { AppBar, Box, CssBaseline, Grid, Toolbar } from '@mui/material';

// project imports
import Header from '../MainLayout/Header';
import SettingsSidebar from './SettingsSidebar';

// ==============================|| SETTINGS LAYOUT ||============================== //

const SettingsLayout = () => {
  const theme = useTheme();

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {/* header */}
      <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{
          bgcolor: theme.palette.background.default,
        }}
      >
        <Toolbar>
          <Header />
        </Toolbar>
      </AppBar>

      <Grid container pt={10} justifyContent="center">
        <Grid item xs={12} sm={4} md={3} lg={2}>
          <SettingsSidebar />
        </Grid>
        <Grid item xs={12} sm={8} lg={7}>
          <Outlet />
        </Grid>
      </Grid>
    </Box>
  );
};

export default SettingsLayout;
