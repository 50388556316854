import * as actionTypes from './actions';

export const initialState = {
  loading: false,
  error: null,
  data: [],
};

const ideaListReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_IDEA_LIST:
      return {
        ...state,
        data: action.payload,
      };
    case actionTypes.ADD_IDEA_TO_LIST:
      return {
        ...state,
        data: [...state.data, action.payload],
      };
    case actionTypes.REMOVE_IDEA_FROM_LIST:
      const indToRemove = state.data.findIndex((el) => el.id === action.payload);
      const newList = [...state.data];
      if (indToRemove >= 0 && indToRemove < newList.length) {
        newList.splice(indToRemove, 1);
      }
      return {
        ...state,
        data: newList,
      };
    default:
      return state;
  }
};

export default ideaListReducer;
