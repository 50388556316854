import main from './main';
import libraries from './libraries';

// ==============================|| MENU ITEMS ||============================== //

const allItems = [main];
if (process.env.NODE_ENV === 'development') {
  allItems.push(libraries);
}

const menuItems = {
  items: allItems,
};

export default menuItems;
