// assets
import { IconComponents, IconHelp, IconPalette, IconShadow, IconWindmill } from '@tabler/icons';

// constant
const icons = {
  IconComponents,
  IconHelp,
  IconPalette,
  IconShadow,
  IconWindmill,
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const libraries = {
  id: 'libraries',
  title: 'Libraries',
  caption: 'Component ref for dev',
  type: 'group',
  children: [
    {
      id: 'components',
      title: 'Components',
      type: 'item',
      icon: icons.IconComponents,
      url: '/lib/components',
      breadcrumbs: false,
    },
    {
      id: 'util-color',
      title: 'Color',
      type: 'item',
      url: '/lib/color',
      icon: icons.IconPalette,
      breadcrumbs: false,
    },
    {
      id: 'util-shadow',
      title: 'Shadow',
      type: 'item',
      url: '/lib/shadow',
      icon: icons.IconShadow,
      breadcrumbs: false,
    },
    {
      id: 'documentation',
      title: 'Documentation',
      type: 'item',
      url: 'https://codedthemes.gitbook.io/berry/',
      icon: icons.IconHelp,
      external: true,
      target: true,
    },
    {
      id: 'icons',
      title: 'Icons',
      type: 'collapse',
      icon: icons.IconWindmill,
      children: [
        {
          id: 'tabler-icons',
          title: 'Tabler Icons',
          type: 'item',
          url: '/icons/tabler-icons',
          breadcrumbs: false,
        },
        {
          id: 'material-icons',
          title: 'Material Icons',
          type: 'item',
          url: 'https://mui.com/material-ui/material-icons/',
          breadcrumbs: false,
          external: true,
          target: true,
        },
      ],
    },
  ],
};

export default libraries;
