import React from 'react';
import PropTypes from 'prop-types';
import { useLoggedInUser } from 'context/LoggedInUserContext';
import { Navigate } from 'react-router';
import { REDIRECT_URL } from 'utils/constant';

// This wrapper auto redirect un-loggedin users to login page
const RouteGuard = ({ redirectTo = '/auth/login', children }) => {
  const { user } = useLoggedInUser();
  if (!user) {
    const redirectUrl = localStorage.getItem(REDIRECT_URL);
    return <Navigate to={`${redirectTo}${redirectUrl ? `?redirect=${encodeURIComponent(redirectUrl)}` : ''}`} replace />;
  }
  return children;
};

RouteGuard.propTypes = {
  redirectTo: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default RouteGuard;
