import * as actionTypes from './actions';

export const initialState = {
  isOpen: false,
  isConverting: false,
  ideaId: '',
  ideaTitle: '',
  cycle: 0,
  templateList: [],
  deviceList: [],
  platform: '',
};

const convertIdeaToExpModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.OPEN_MODAL:
      const { ideaId, ideaTitle } = action.payload || {};
      return {
        ...state,
        isOpen: true,
        ideaId: ideaId,
        ideaTitle: ideaTitle,
      };
    case actionTypes.CLOSE_MODAL:
      return {
        ...state,
        isOpen: false,
      };
    case actionTypes.SET_IS_CONVERTING:
      const { isConverting } = action.payload || {};
      return {
        ...state,
        isConverting,
      };
    case actionTypes.SET_MODAL_STATE:
      const fields = action.payload || {};
      return {
        ...state,
        ...fields,
      };
    case actionTypes.CONVERT_IDEA_TO_EXP_SUCCESS:
      return {
        ...state,
        isOpen: false,
        isConverting: false,
      };
    default:
      return state;
  }
};

export default convertIdeaToExpModalReducer;
