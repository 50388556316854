import { ArrowCircleLeftOutlined } from '@mui/icons-material';
import { Stack, styled } from '@mui/material';
import settingsMenuItems from 'menu-items/settings';
import React from 'react';
import { Link, useParams } from 'react-router-dom';

const SettingsSidebar = () => {
  const { segment } = useParams();
  return (
    <Stack className="SettingsSidebar" p={1}>
      <StyledLink key="back-to-home" to="/" sx={{ display: 'flex', alignItems: 'center', px: 2, py: 1.25, mb: 1 }}>
        <ArrowCircleLeftOutlined sx={{ margin: '0 4px 3px 0px', fontSize: '22px' }} /> Back
      </StyledLink>
      {settingsMenuItems.map((el) => (
        <StyledLink key={el.key} to={`/settings/${el.key}`} selected={el.key === segment}>
          {el.value}
        </StyledLink>
      ))}
    </Stack>
  );
};

const StyledLink = styled(Link, { shouldForwardProp: (p) => p !== 'selected' })(({ theme, selected }) => ({
  padding: `${theme.spacing(1.5)} ${theme.spacing(2.5)}`,
  textDecoration: 'none',
  fontSize: '16px',
  fontWeight: 600,
  transition: 'all 100ms 30ms ease-out',
  borderRightWidth: '3px',
  borderRightStyle: 'solid',
  borderRightColor: selected ? theme.palette.primary.main : 'transparent',
  color: selected ? theme.palette.primary.main : theme.palette.secondary.main,
  backgroundColor: selected ? theme.palette.primary[200] + '20' : 'transparent',
  '&:hover': {
    backgroundColor: theme.palette.primary[200] + (selected ? '20' : '10'),
  },
}));

export default SettingsSidebar;
