import { Box, Button, InputBase, Link, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { IconExternalLink } from '@tabler/icons';
import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { getJiraApiToken, saveJiraApiToken } from 'service/jiraApiToken';
import { SHOW_NOTI } from 'store/actions';
import { QUERY_KEYS } from 'utils/constant';
import { formatDateTimeMedium } from 'utils/function';

const JiraSegment = () => {
  const [jiraEmail, setJiraEmail] = useState('');
  const [jiraApiToken, setJiraApiToken] = useState('');
  const dispatch = useDispatch((state) => state.snackConfig);

  const queryClient = useQueryClient();
  const { isLoading, isError, data } = useQuery(QUERY_KEYS.JIRA_TOKEN_INFO, getJiraApiToken);
  const { mutate, isLoading: isSaving } = useMutation(saveJiraApiToken, {
    onSuccess: () => {
      setJiraEmail('');
      setJiraApiToken('');
      dispatch({
        type: SHOW_NOTI,
        payload: {
          message: 'Save Success',
          variant: 'success',
        },
      });
      queryClient.invalidateQueries(QUERY_KEYS.JIRA_TOKEN_INFO);
    },
    onError: (err) => {
      console.log(err);
      dispatch({
        type: SHOW_NOTI,
        payload: {
          message: 'Something went wrong',
          variant: 'error',
        },
      });
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const basicToken = btoa(jiraEmail + ':' + jiraApiToken);
    // console.log({ basicToken });
    mutate({ jiraEmail, jiraApiToken, basicToken });
  };

  return (
    <Stack py={1} px={2} className="JiraSegment">
      <Typography variant="h3">Jira API Token</Typography>
      <Typography variant="body1" my={2}>
        CRO operation platform needs to save your jira personal api token to authenticate and connect with Jira Software. You can create
        your api token{' '}
        <StyledLink href="https://id.atlassian.com/manage-profile/security/api-tokens" target="_blank">
          <span>here</span>
          <IconExternalLink size={14} stroke={2} />
        </StyledLink>
        . New tokens may take up to a minute to work after they&apos;ve been created.
      </Typography>
      <Typography variant="body1" mb={1}>
        Note that you will be able to see the token only 1 time. Therefore, make sure you copy it and keep it safe.
      </Typography>
      {!isLoading && !isError && data?.data && (
        <Box py={2}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  <strong>Jira Email</strong>
                </TableCell>
                <TableCell>
                  <strong>Created</strong>
                </TableCell>
                <TableCell>
                  <strong>Last Updated</strong>
                </TableCell>
                <TableCell>
                  <strong>Action</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{data.data.jiraEmail}</TableCell>
                <TableCell>{formatDateTimeMedium(data.data.created_at)}</TableCell>
                <TableCell>{formatDateTimeMedium(data.data.updated_at)}</TableCell>
                <TableCell>
                  <Button size="small" color="error">
                    Revoke
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      )}
      <form onSubmit={handleSubmit} autoComplete="off">
        <Stack gap={2.5} alignItems="flex-start" pt={1.5}>
          <Stack>
            <StyledLabel htmlFor="jiraEmail">Jira Email:</StyledLabel>
            <StyledInput
              type="email"
              id="jiraEmail"
              name="jiraEmail"
              value={jiraEmail}
              onChange={(e) => setJiraEmail(e.target.value)}
              autoComplete="off"
            />
            <small style={{ opacity: 0.7 }}>
              This is the email you used to sign up for Jira. You can double check{` `}
              <StyledLink href="https://id.atlassian.com/manage-profile/profile-and-visibility" target="_blank">
                <span>here</span>
                <IconExternalLink size={14} stroke={2} />
              </StyledLink>
            </small>
          </Stack>
          <Stack>
            <StyledLabel htmlFor="jiraApiToken">Jira API Token:</StyledLabel>
            <StyledInput
              type="password"
              id="jiraApiToken"
              name="jiraApiToken"
              value={jiraApiToken}
              onChange={(e) => setJiraApiToken(e.target.value)}
              autoComplete="off"
            />
          </Stack>
          <Button type="submit" variant="contained" color="primary" size="small" disableElevation disableRipple disabled={isSaving}>
            <strong>Save Token</strong>
          </Button>
        </Stack>
      </form>
    </Stack>
  );
};

const StyledLabel = styled('label')(({ theme }) => ({
  fontSize: '13px',
  fontWeight: 600,
  marginBottom: theme.spacing(0.5),
}));

const StyledInput = styled(InputBase)(({ theme }) => ({
  border: `2px solid ${theme.palette.primary.light}`,
  backgroundColor: theme.palette.primary[200] + '10',
  borderRadius: '3px',
  padding: '2px 8px',
  minWidth: '260px',
  fontSize: '15px',
}));

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.info.main,
  fontWeight: 600,
  textUnderlineOffset: '2px',
  textDecorationThickness: '2px',
  textDecorationColor: theme.palette.info.light,
  display: 'inline-flex',
  alignItems: 'center',
  gap: '2px',
}));

export default JiraSegment;
