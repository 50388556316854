import * as actionTypes from './actions';

export const initialState = {
  open: false,
  title: '',
  description: '',
  okBtnText: 'OK',
  cancelBtnText: 'Cancel',
  okBtnVariant: 'contained',
  cancelBtnVariant: 'default',
  okBtnColor: 'primary',
  cancelBtnColor: 'secondary',
  onOk: null,
  onCancel: null,
};

const dialogReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SHOW_DIALOG:
      const { title, description, okBtnText, cancelBtnText, okBtnVariant, okBtnColor, cancelBtnColor, cancelBtnVariant, onOk, onCancel } =
        action.payload || {};
      return {
        ...state,
        open: true,
        title: title || '',
        description: description || '',
        okBtnText: okBtnText || 'OK',
        cancelBtnText: cancelBtnText || 'Cancel',
        okBtnVariant: okBtnVariant || 'contained',
        cancelBtnVariant: cancelBtnVariant || 'default',
        okBtnColor: okBtnColor || 'primary',
        cancelBtnColor: cancelBtnColor || 'secondary',
        onOk: onOk || null,
        onCancel: onCancel || null,
      };
    case actionTypes.CLOSE_DIALOG:
      return {
        ...state,
        open: false,
      };
    default:
      return state;
  }
};

export default dialogReducer;
